import { mapGetters, mapActions } from "vuex";
import SdkMixins from "@/mixins/SdkMixins";
import Dropdown from "@/components/Dropdown";
import SignUpModal from "@/components/SignUpModal";
import LoginModal from "@/components/LoginModal";
import ResetPasswordModal from "@/components/ResetPasswordModal";
import UpdateUserInfoRegisterModal from "@/components/UpdateUserInfoRegisterModal";
import UserAvatar from "@/components/UserAvatar";
import { SDK_API } from "@/configs/APIs";
import { STATUS_RESPONSES } from "@/configs/SDK";
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from "@/configs/Settings";
import { DEFAULT_ICON_STATES } from "@/configs/Icons";

export default {
	name: "Navbar",
	mixins: [SdkMixins],
	components: {
		SignUpModal,
		LoginModal,
		ResetPasswordModal,
		UpdateUserInfoRegisterModal,
		Dropdown,
		UserAvatar
	},
	props: {
		isHome: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			ggPlayLink: GOOGLE_PLAY_LINK,
			appStoreLink: APP_STORE_LINK,
			navCollapse: false,
			showLogin: false,
			showSignUp: false,
			showUpdate: false,
			showResetPassword: false,
			showDownloadDialog: false,
			forgotPwd: false,
			company: null,
			userSocial: null,
			signInType: -1,
			DEFAULT_ICON_STATES
		};
	},
	computed: {
		...mapGetters(["isLoggedIn", "user", "categories", "webLoaded", "country", "resources"]),
		getValidCategory() {
			return this.categories.filter((x) => x.tagId >= 0);
		}
	},
	watch: {
		// eslint-disable-next-line no-unused-vars
		$route(to, from) {
			this.$refs.navCollapse.classList.remove("navigation__collapse--show");
			this.navCollapse = false;
			this.checkIsRegister(to);
		},
	},
	created() { },
	async mounted() {
		this.checkIsRegister(this.$route);
		await this.getAllCategory();

		this.$root.$on("required-login", () => {
			this.showLogin = true;
		});
		this.$root.$on("show-signup", () => {
			this.forgotPwd = true;
			this.showSignUp = true;
		});
		this.$root.$on("signup-with-social-networks", (user, signInType) => {
			this.userSocial = user;
			this.signInType = signInType;
			this.showUpdate = true;
		});
	},
	methods: {
		...mapActions(["updateCategories"]),
		handleOpenSignUp() {
			this.showSignUp = true;
		},
		handleCloseSignUp() {
			this.forgotPwd = false;
			this.company = null;
			this.userSocial = null;
			this.signInType = -1;
			this.showSignUp = false;
			if (this.$route.name === "Register") {
				this.$router.push("/");
			}
		},
		checkIsRegister(route) {
			if (!route) return;
			if (route.name === "Register") {
				if (route.query.code) this.referralCode = route.query.code;
				if (route.params.company) this.company = route.params.company;
				this.showSignUp = true;
			}
		},
		toggleNav() {
			this.$refs.navCollapse.classList.toggle("navigation__collapse--show");
			this.navCollapse = !this.navCollapse;
		},
		async getAllCategory() {
			const {
				data: { Code, Data }
			} = await this.$http.post(SDK_API.GET_ALL_STREAM_TAGS);
			if (Code === STATUS_RESPONSES.SUCCESS) {
				let categories = Data.filter((category) => {
					return category.TagName !== "Multi-Guest" && category.TagName !== "Game"; // Hard code temp
				});
				this.updateCategories(categories);
			}
		},
	}
};
