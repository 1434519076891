//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "Checkbox",
  props: {
    vid: {
      type: String
    },
    rules: {
      type: String
    },
    mode: {
      type: String
    },
    variant: {
      type: String,
      default: "primary"
    },
    label: {
      type: String
    },
    labelClass: {
      type: String
    },
    value: {
      type: Boolean,
      default: false
    },
    showError: {
      type: Boolean,
      default: false
    }
  },
  mounted: function mounted() {},
  computed: {
    checked: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit("input", val);
      }
    },
    getClass: function getClass() {
      if (this.variant === "primary") return "checkmark--linear";
      return "";
    }
  }
};