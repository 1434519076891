import _objectSpread from "/home/ubuntu/projects/WebStream/node_modules/@babel/runtime/helpers/esm/objectSpread2";
var initalState = {
  user: {
    UserInfo: {}
  },
  country: null,
  webLoaded: false,
  previousPath: null
};
var getters = {
  user: function user(state) {
    return state.user;
  },
  isLoggedIn: function isLoggedIn(state) {
    var _state$user, _state$user$UserInfo, _state$user2;

    return (_state$user = state.user) !== null && _state$user !== void 0 && (_state$user$UserInfo = _state$user.UserInfo) !== null && _state$user$UserInfo !== void 0 && _state$user$UserInfo.UserId && !state.user.GuestId && ((_state$user2 = state.user) === null || _state$user2 === void 0 ? void 0 : _state$user2.UserInfo.DisplayName.indexOf("+guest_")) <= -1 ? true : false;
  },
  country: function country(state) {
    return state.country;
  },
  webLoaded: function webLoaded(state) {
    return state.webLoaded;
  },
  previousPath: function previousPath(state) {
    return state.previousPath;
  }
};
var mutations = {
  USER_INFO: function USER_INFO(state, userInfo) {
    if (!userInfo) {
      state.user = _objectSpread(_objectSpread({}, state.user), {}, {
        userInfo: {}
      });
      return;
    }

    state.user = _objectSpread(_objectSpread({}, state.user), {}, {
      UserInfo: _objectSpread(_objectSpread({}, state.user.UserInfo), userInfo)
    });
  },
  USER_LOGIN: function USER_LOGIN(state, user) {
    // Guest:
    if (!user) {
      state.user = {};
      return;
    }

    state.user.GuestId = user && user.GuestId ? user.GuestId : 0;
    state.user = _objectSpread({}, user);
  },
  USER_LOGOUT: function USER_LOGOUT(state, user) {
    state.user.GuestId = user && user.GuestId ? user.GuestId : 0;
  },
  SET_COUNTRY: function SET_COUNTRY(state, country) {
    state.country = country;
  },
  SET_WEB_LOADED: function SET_WEB_LOADED(state, loaded) {
    state.webLoaded = loaded;
  },
  SET_PREVIOUS_PATH: function SET_PREVIOUS_PATH(state, path) {
    state.previousPath = path;
  }
};
var actions = {
  userLogin: function userLogin(_ref, user) {
    var commit = _ref.commit;
    localStorage.removeItem("currentUser");
    localStorage.setItem("currentUser", JSON.stringify(user));
    commit("USER_LOGIN", user);
  },
  userLogout: function userLogout(_ref2, user) {
    var commit = _ref2.commit;
    localStorage.removeItem("currentUser");
    localStorage.setItem("currentUser", JSON.stringify(user));
    commit("USER_LOGOUT", user);
  },
  setWebLoaded: function setWebLoaded(_ref3, loaded) {
    var commit = _ref3.commit;
    commit("SET_WEB_LOADED", loaded);
  },
  setPreviousPath: function setPreviousPath(_ref4, path) {
    var commit = _ref4.commit;
    commit("SET_PREVIOUS_PATH", path);
  }
};
export default {
  state: initalState,
  getters: getters,
  mutations: mutations,
  actions: actions
};