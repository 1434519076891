import "core-js/modules/es.array.concat.js";
export var ENDPOINT_SERVER = "".concat(process.env.VUE_APP_BELIVESDK_WEB_APP_ENDPOINT, "/api");
export var LOGIN_REDIRECT_URL = "http://localhost:8080/auth/socials/callback/line";
export var SDK_API = {
  // Generals:
  GET_ALL_STREAM_TAGS: "".concat(ENDPOINT_SERVER, "/streams/tags"),
  GET_CURRENT_EVENTS: "".concat(ENDPOINT_SERVER, "/events/current-events"),
  GET_POPULAR_VIDEOS: "".concat(ENDPOINT_SERVER, "/streams/popular"),
  // User login/register
  GUEST_LOGIN: "".concat(ENDPOINT_SERVER, "/users/guest-login"),
  CHECK_LOGIN: "".concat(ENDPOINT_SERVER, "/users/check-login"),
  PHONE_LOGIN: "".concat(ENDPOINT_SERVER, "/users/login-phone"),
  LOGIN: "".concat(ENDPOINT_SERVER, "/users/login"),
  REGISTER_SOCIALS: "".concat(ENDPOINT_SERVER, "/users/register"),
  SEND_OTP: "".concat(ENDPOINT_SERVER, "/users/send-otp"),
  VERIFY_OTP: "".concat(ENDPOINT_SERVER, "/users/verify-otp"),
  VERIFY_PHONE: "".concat(ENDPOINT_SERVER, "/users/verify-phone"),
  FORGOT_PASSWORD: "".concat(ENDPOINT_SERVER, "/users/forgot-password"),
  RESET_PASSWORD: "".concat(ENDPOINT_SERVER, "/users/reset-password"),
  VERIFY_USERNAME: "".concat(ENDPOINT_SERVER, "/users/verify-username"),
  VERIFY_EMAIL: "".concat(ENDPOINT_SERVER, "/users/verify-email"),
  // User follow/unfollow:
  FOLLOW: "".concat(ENDPOINT_SERVER, "/follows/follow"),
  UNFOLLOW: "".concat(ENDPOINT_SERVER, "/follows/unfollow"),
  IS_FOLLOWING: "".concat(ENDPOINT_SERVER, "/follows/is-following"),
  USER_PROFILE: "".concat(ENDPOINT_SERVER, "/users/detail"),
  EDIT_PROFILE: "".concat(ENDPOINT_SERVER, "/users/edit-profile"),
  // Cash-out:
  CREATE_CASH_OUT: "".concat(ENDPOINT_SERVER, "/cashouts/create"),
  USER_STREAMS: "".concat(ENDPOINT_SERVER, "/users/streams"),
  GET_STATISTIC_STREAM: "".concat(ENDPOINT_SERVER, "/streams/statistic"),
  //Stream
  GET_STREAM_DETAIL: "".concat(ENDPOINT_SERVER, "/streams/detail"),
  LIKE_STREAM: "".concat(ENDPOINT_SERVER, "/streams/like"),
  GET_PRODUCTS_IN_STREAM: "".concat(ENDPOINT_SERVER, "/streams/products"),
  GET_PINNED_MESSAGE: "".concat(ENDPOINT_SERVER, "/streams/pinned"),
  LEAVE_STREAM: "".concat(ENDPOINT_SERVER, "/streams/leave-stream"),
  REPORT_STREAM: "".concat(ENDPOINT_SERVER, "/streams/report")
};
export var PUBLIC_APIs = {
  LINE_LOGIN: "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=".concat(process.env.VUE_APP_LINE_CLIENT_ID, "&redirect_uri=").concat(LOGIN_REDIRECT_URL, "&scope=profile%20email%20openid"),
  LINE_TOKEN: "https://api.line.me/oauth2/v2.1/token",
  LINE_VERIFY: "https://api.line.me/oauth2/v2.1/verify"
};