import "/home/ubuntu/projects/WebStream/node_modules/core-js/modules/es.array.iterator.js";
import "/home/ubuntu/projects/WebStream/node_modules/core-js/modules/es.promise.js";
import "/home/ubuntu/projects/WebStream/node_modules/core-js/modules/es.object.assign.js";
import "/home/ubuntu/projects/WebStream/node_modules/core-js/modules/es.promise.finally.js";
import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import "./plugins/infinite-loading";
import "./plugins/vue-cookies";
import "./plugins/vue-toastr";
import "./plugins/axios";
import "./plugins/firebase";
import "./plugins/fg-loadcss";
import "./plugins/vee-validate";
import "./plugins/vue-lazy-load";
import "./plugins/font-awesome-icon";
import "./directives/click-outside";
import "./directives/event-listener";
import "./directives/touch-event";
import "./directives/scroll-infinite";
import { i18n } from "./plugins/i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store";
Vue.config.productionTip = false;
new Vue({
  i18n: i18n,
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');