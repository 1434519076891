//
//
//
//
//
//
//
import LOGO_COLOR from "@/assets/media/logo-color.png";
export default {
  name: "LoadingPanel",
  components: {},
  props: {},
  computed: {},
  data: function data() {
    return {
      LOGO_COLOR: LOGO_COLOR
    };
  },
  beforeDestroy: function beforeDestroy() {}
};