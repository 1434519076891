export var AGORA_CONNECTION_STATE = {
  STATUS: {
    CONNECTED: "CONNECTED",
    ABORTED: "ABORTED",
    DISCONNECTED: "DISCONNECTED",
    CONNECTING: "CONNECTING",
    RECONNECTING: "RECONNECTING"
  },
  REASON: {
    REMOTE_LOGIN: "REMOTE_LOGIN"
  }
};
export var AGORA_ERROR_CODES = {
  CHANNEL_JOIN_SUCCESS: "RTM Channel Join success",
  CHANNEL_JOIN_FAIL: "RTM Channel Join fail",
  DISCONNECTED: "RTM disconnected",
  CONNECTED: "RTM connected",
  CONNECTING: "RTM connecting",
  RECONNECTING: "RTM re-connecting",
  REMOTE_LOGIN: "RTM multiple device loginRTM Login success"
};