export var RTM_MESSAGE_TYPES = {
  MESSAGE: "message",
  USER_LEFT: "userLeft",
  USER_JOIN: "userJoin",
  FOLLOW_HOST: "followHost",
  LIKE: "like",
  GIFT: "gift",
  STATISTIC: "statistic",
  END: "end",
  SHARE_LIVE_STREAM: "shareLiveStream",
  TEXT_STICKER: "textSticker",
  BLOCK: "block",
  UNBLOCK: "unblock",
  QUESTION_QUIZ: "question-quiz",
  RESULT_QUESTION_QUIZ: "result-question-quiz",
  START_QUIZ: "start-quiz",
  STOP_QUIZ: "stop-quiz",
  WAITING_SCREEN_QUIZ: "waiting-screen-quiz",
  FINAL_RESULT_QUIZ: "final-result-quiz"
};
export var RTM_MODES = {
  GROUPMODE_MUTEALL: 0,
  GROUPMODE_ABLECHAT: 1,
  GROUPMODE_ONLYHOST: 2
};
export var RTM_CONFIGS = {
  MAXIMUM_RECEIVED_LIKES: 20,
  DELAY_ANIMATION_LIKE: 200
};
export var AGORA_RTM_MESSAGE_TYPES = {
  MESSAGE: "message",
  USER_LEFT: "userLeft",
  USER_JOIN: "userJoin",
  FOLLOW_HOST: "followHost",
  LIKE: "like",
  GIFT: "gift",
  STATISTIC: "statistic",
  END: "end",
  SHARE_LIVE_STREAM: "shareLiveStream",
  TEXT_STICKER: "textSticker",
  BLOCK: "block",
  UNBLOCK: "unblock"
};
export var AGORA_OPTIONS = {
  APP_ID: "0d1ca1df6ee04bca9fac4f27a4b9e264",
  channel: "Agora Channel",
  uid: null,
  token: null
};
export var ADMIN_RTM_CONFIGS = {
  CHANNEL_EVENTS: ["ChannelMessage" // "MemberJoined",
  // "MemberLeft"
  ],
  PREFIX_METHOD: "receivedAdmin"
};
export var ADMIN_RTM_MESSAGE_TYPES = {
  QUESTION: 0,
  RESULT: 1,
  FINISH: 2
};