import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from "vue";
import VueI18n from "vue-i18n"; // import en from "@/messages/lang/en";

import en from "@/messages/lang/en";
import axios from "./axios";
Vue.use(VueI18n); // Ready translated locale messages
// Create VueI18n instance with options

export var i18n = new VueI18n({
  locale: "en",
  // set locale
  fallbackLocale: "en",
  messages: {
    en: en
  },
  // set locale messages
  silentTranslationWarn: true
});
var loadedLanguages = ["en"]; // our default language that is preloaded

function setI18nLanguage(lang) {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export function loadLanguageAsync(lang) {
  // If the same language or If the language was already loaded
  if (i18n.locale === lang || loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  } // If the language hasn't been loaded yet


  return import(
  /* webpackChunkName: "lang-[request]" */
  "@/messages/lang/".concat(lang, ".js")).then(function (messages) {
    i18n.setLocaleMessage(lang, messages.default);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
  });
}