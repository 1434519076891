import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { DEFAULT_ICON_STATES } from "@/configs/Icons";
export default {
  name: "UserAvatar",
  props: {
    src: {
      type: String
    },
    specificUser: {
      type: String,
      default: ""
    },
    level: {
      type: Number
    },
    hideVipFrame: {
      type: Boolean,
      required: false
    },
    size: {
      type: String,
      default: "4rem"
    }
  },
  data: function data() {
    return {
      defaultImg: DEFAULT_ICON_STATES.defaultAvatar.activeSrc,
      error: false
    };
  },
  computed: {
    getAvatarSrc: function getAvatarSrc() {
      return this.error ? this.defaultImg : this.src || this.defaultImg || null;
    }
  }
};