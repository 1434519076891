//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { RANDOM_COLORS } from "@/configs/Settings";
export default {
  name: "Heart",
  data: function data() {
    return {
      randomColor: Math.floor(Math.random() * 5 + 1),
      // randomOutline: Math.floor((Math.random() * 5) + 1),
      RANDOM_COLORS: RANDOM_COLORS
    };
  },
  props: {
    bgClassAnimation: {
      type: String,
      default: "animation-style-1"
    },
    animationClass: {
      type: String,
      default: "bg-animation-1"
    }
  },
  mounted: function mounted() {}
};