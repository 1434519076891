var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user)?_c('RtmAgora',_vm._b({ref:"rtmSdk",attrs:{"is-update-value-model":false,"format-received-message":_vm.formatNewMessage,"channel":_vm.channel,"license":_vm.license,"server":_vm.server,"user":_vm.user,"app-id":_vm.appId,"uid":_vm.user.UserName,"options":_vm.options,"rtm-message-types":_vm.RTM_MESSAGE_TYPES,"format-message-before-send":_vm.formatMessageBeforeSend,"otherChannels":_vm.otherChannels,"rtm-threshold-sec":_vm.LIMIT_CHAT_CONFIGS.THRESHOLD_MESSAGES,"is-apply-limit":_vm.LIMIT_CHAT_CONFIGS.IS_APPLY_LIMIT,"rtm-limiter-rate":_vm.LIMIT_CHAT_CONFIGS.CAP_TARGET_MESSAGES,"id":"chatWrapper"},on:{"update-text-messages":_vm.updateTextMessages,"max-length-alert":_vm.showAlertMaxLength,"receivedAdminChannelMessage":_vm.receivedAdminChannelMessage,"connection-state-changed":_vm.connectionStateChanged,"failed-connect-channel":_vm.failedConnectRtmChannel,"message-from-peer":_vm.messageFromPeer,"reject-send-message":_vm.rejectSendMessage},scopedSlots:_vm._u([{key:"middle-layout",fn:function(ref){
var inputAttrs = ref.inputAttrs;
var inputEvents = ref.inputEvents;
var sendMessage = ref.sendMessage;
return [_c('div',{staticClass:"chat-wrapper",class:_vm.requiredLoginSignUpPopUp ? 'required-login' : ''},[_c('div',{staticClass:"middle-wrapper"},[_vm._t("prepend-middle-wrapper-layout"),_c('div',{ref:"messagesWrapper",staticClass:"middle-wrapper__messages"},[_vm._l((_vm.textChannelMessages),function(message,index){return _c('div',{key:index,staticClass:"item"},[(
                message.MessageType === _vm.RTM_MESSAGE_TYPES.MESSAGE ||
                message.MessageType === _vm.RTM_MESSAGE_TYPES.USER_JOIN ||
                message.MessageType === _vm.RTM_MESSAGE_TYPES.FOLLOW_HOST ||
                (message.MessageType === _vm.RTM_MESSAGE_TYPES.LIKE &&
                  message.IsLiked)
              )?_c('div',{class:("item__message-room " + (_vm.getClassFromTypeMessage(
                message.MessageType
              )))},[_c('span',{staticClass:"username"},[_vm._v(_vm._s(message.DisplayName))]),(message.MessageType === _vm.RTM_MESSAGE_TYPES.LIKE)?_c('span',{staticClass:"message-content",domProps:{"innerHTML":_vm._s(_vm.formatMessageRoom(message))}}):_c('span',{staticClass:"message-content"},[_vm._v(_vm._s(_vm.formatMessageRoom(message)))])]):_vm._e()])}),_c('div',{ref:"bottomAnchor",staticClass:"item"})],2)],2),_c('div',{staticClass:"bottom-wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isUsingLocalMessages),expression:"!isUsingLocalMessages"}],staticClass:"actions-wrapper",on:{"click":_vm.authenLoginFunction}},[_c('textarea',_vm._g(_vm._b({staticClass:"actions-wrapper__editor",attrs:{"placeholder":'Say something...',"disabled":_vm.requiredLoginSignUpPopUp,"readonly":_vm.requiredLoginSignUpPopUp}},'textarea',inputAttrs,false),inputEvents)),_c('button',{staticClass:"actions-wrapper__send-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.authenLoginFunction(sendMessage, [inputAttrs.value])}}},[_vm._v(" Send ")])]),_vm._t("append-actions-layout")],2),_vm._t("last-layout-wrapper")],2)]}}],null,true),model:{value:(_vm.textMessages),callback:function ($$v) {_vm.textMessages=$$v},expression:"textMessages"}},'RtmAgora',_vm.getAgoraOption,false),[_c('template',{slot:"before-layout"},[(_vm.streamDetail.Publisher)?_c('HostInfoCard',{class:_vm.requiredLoginSignUpPopUp ? 'required-login' : '',attrs:{"is-mobile-layout":true},on:{"send-follow-message":_vm.sendFollowMessage}}):_vm._e()],1),_c('template',{slot:"after-layout"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]})])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }