import "core-js/modules/es.function.name.js";
import { LIMIT_CHAT_CONFIGS } from "@/configs/Settings";
var state = {
  client: {},
  channels: {},
  isLoggedInRtm: false,
  reInitRtm: false,
  publicKey: null,
  privateKey: null,
  currentChannel: null,
  isJoinedChannel: false,
  limitChatConfigs: {
    rtmWaitingSec: LIMIT_CHAT_CONFIGS.WAITING_TIME,
    rtmLimiterRate: LIMIT_CHAT_CONFIGS.CAP_TARGET_MESSAGES,
    rtmThresholdSec: LIMIT_CHAT_CONFIGS.THRESHOLD_MESSAGES
  }
};
var getters = {
  client: function client(state) {
    return state.client;
  },
  channels: function channels(state) {
    return state.channels;
  },
  isLoggedInRtm: function isLoggedInRtm(state) {
    return state.isLoggedInRtm;
  },
  reInitRtm: function reInitRtm(state) {
    return state.reInitRtm;
  },
  publicKey: function publicKey(state) {
    return state.publicKey;
  },
  privateKey: function privateKey(state) {
    return state.privateKey;
  },
  currentChannel: function currentChannel(state) {
    return state.currentChannel;
  },
  isJoinedChannel: function isJoinedChannel(state) {
    return state.isJoinedChannel;
  },
  limitChatConfigs: function limitChatConfigs(state) {
    return state.limitChatConfigs;
  }
};
var mutations = {
  UPDATE_CLIENT: function UPDATE_CLIENT(state, client) {
    state.client = client;
  },
  UPDATE_CHANNEL: function UPDATE_CHANNEL(state, data) {
    state.channels[data.name] = data.channel;
  },
  DELETE_CHANNEL: function DELETE_CHANNEL(state, data) {
    // myArr = myArray.filter(obj => obj.id !== id);
    state.channels[data.name] = data.channel;
    delete state.channels[data.name];
  },
  UPDATE_STATUS_JOIN_OF_CHANNEL: function UPDATE_STATUS_JOIN_OF_CHANNEL(state, data) {
    state.channels[data.name].joined = data.joined;
  },
  UPDATE_IS_LOGGED_IN_RTM: function UPDATE_IS_LOGGED_IN_RTM(state, isLoggedInRtm) {
    state.isLoggedInRtm = isLoggedInRtm;
  },
  UPDATE_RE_INIT_RTM: function UPDATE_RE_INIT_RTM(state, reInitRtm) {
    state.reInitRtm = reInitRtm;
  },
  UPDATE_PUBLIC_KEY: function UPDATE_PUBLIC_KEY(state, publicKey) {
    state.publicKey = publicKey;
  },
  UPDATE_PRIVATE_KEY: function UPDATE_PRIVATE_KEY(state, privateKey) {
    state.privateKey = privateKey;
  },
  UPDATE_CURRENT_CHANNEL: function UPDATE_CURRENT_CHANNEL(state, currentChannel) {
    state.currentChannel = currentChannel;
  },
  UPDATE_IS_JOINED_CHANNEL: function UPDATE_IS_JOINED_CHANNEL(state, isJoinedChannel) {
    state.isJoinedChannel = isJoinedChannel;
  },
  UPDATE_LIMIT_CHAT_CONFIGS: function UPDATE_LIMIT_CHAT_CONFIGS(state, limitChatConfigs) {
    state.limitChatConfigs = Object.assign(state.limitChatConfigs, limitChatConfigs);
  }
};
var actions = {
  updateClient: function updateClient(_ref, client) {
    var commit = _ref.commit;
    commit('UPDATE_CLIENT', client);
  },
  updateChannel: function updateChannel(_ref2, data) {
    var commit = _ref2.commit;
    commit('UPDATE_CHANNEL', data);
  },
  deleteChannel: function deleteChannel(_ref3, data) {
    var commit = _ref3.commit;
    commit('DELETE_CHANNEL', data);
  },
  updateStatusJoinOfChannel: function updateStatusJoinOfChannel(_ref4, data) {
    var commit = _ref4.commit;
    commit('UPDATE_STATUS_JOIN_OF_CHANNEL', data);
  },
  updateIsLoggedInRtm: function updateIsLoggedInRtm(_ref5, data) {
    var commit = _ref5.commit;
    commit('UPDATE_IS_LOGGED_IN_RTM', data);
  },
  updateReInitRtm: function updateReInitRtm(_ref6, data) {
    var commit = _ref6.commit;
    commit('UPDATE_RE_INIT_RTM', data);
  },
  updatePublicKey: function updatePublicKey(_ref7, data) {
    var commit = _ref7.commit;
    commit('UPDATE_PUBLIC_KEY', data);
  },
  updatePrivateKey: function updatePrivateKey(_ref8, data) {
    var commit = _ref8.commit;
    commit('UPDATE_PRIVATE_KEY', data);
  },
  updateCurrentChannel: function updateCurrentChannel(_ref9, currentChannel) {
    var commit = _ref9.commit;
    commit('UPDATE_CURRENT_CHANNEL', currentChannel);
  },
  updateIsJoinedChannel: function updateIsJoinedChannel(_ref10, isJoined) {
    var commit = _ref10.commit;
    commit('UPDATE_IS_JOINED_CHANNEL', isJoined);
  },
  updateLimitChatConfigs: function updateLimitChatConfigs(_ref11, limitChatConfigs) {
    var commit = _ref11.commit;
    commit('UPDATE_LIMIT_CHAT_CONFIGS', limitChatConfigs);
  }
};
export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};