import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
import { RTM_CONFIGS } from "@/configs/RTM_SDK";
var state = {
  streamDetail: null,
  streamStatistic: {
    viewCount: 1,
    likeCount: 0
  },
  hostInfo: null,
  streamProducts: [],
  listLikedAnimations: [],
  listRenderAnimations: [],
  currentIndexRender: -1,
  intervalUpdateRenderId: null,
  channelTextSticker: null
};
var getters = {
  streamDetail: function streamDetail(state) {
    return state.streamDetail;
  },
  streamStatistic: function streamStatistic(state) {
    return state.streamStatistic;
  },
  hostInfo: function hostInfo(state) {
    return state.hostInfo;
  },
  streamProducts: function streamProducts(state) {
    return state.streamProducts;
  },
  listLikedAnimations: function listLikedAnimations(state) {
    return state.listLikedAnimations;
  },
  listRenderAnimations: function listRenderAnimations(state) {
    return state.listRenderAnimations;
  },
  channelTextSticker: function channelTextSticker(state) {
    return state.channelTextSticker;
  }
};
var mutations = {
  UPDATE_STREAM_DETAIL: function UPDATE_STREAM_DETAIL(state, streamDetail) {
    if (streamDetail && state.streamDetail) {
      state.streamDetail = Object.assign(state.streamDetail, streamDetail);
    } else {
      state.streamDetail = streamDetail;
    }
  },
  UPDATE_HOST_INFO: function UPDATE_HOST_INFO(state, hostInfo) {
    if (hostInfo && state.hostInfo) {
      state.hostInfo = Object.assign(state.hostInfo, hostInfo);
    } else {
      state.hostInfo = hostInfo;
    }
  },
  UPDATE_STREAM_STATISTIC: function UPDATE_STREAM_STATISTIC(state, streamStatistic) {
    state.streamStatistic = Object.assign(state.streamStatistic, streamStatistic);
  },
  UPDATE_STREAM_PRODUCTS: function UPDATE_STREAM_PRODUCTS(state, streamProducts) {
    state.streamProducts = streamProducts.sort(function (a, b) {
      return a.orderIndex - b.orderIndex;
    });
  },
  RESORT_STREAM_PRODUCTS: function RESORT_STREAM_PRODUCTS(_ref, sortProduct) {
    var state = _ref.state,
        commit = _ref.commit;
    var productSorted = state.streamProducts.filter(function (product) {
      return parseInt(product.id) === parseInt(sortProduct.productId);
    });

    if (productSorted.length) {
      var oldIndex = productSorted[0].orderIndex;

      if (oldIndex > sortProduct.newIndex) {
        state.streamProducts.map(function (product) {
          if (product.orderIndex <= oldIndex && product.orderIndex > sortProduct.newIndex) {
            return product.orderIndex++;
          }
        });
      } else if (oldIndex < sortProduct.newIndex) {
        state.streamProducts.map(function (product) {
          if (product.orderIndex >= oldIndex && product.orderIndex < sortProduct.newIndex) {
            return product.orderIndex--;
          }
        });
      }

      commit("UPDATE_STREAM_PRODUCTS", state.streamProducts);
    }
  },
  UPDATE_LIST_LIKED_ANIMATIONS: function UPDATE_LIST_LIKED_ANIMATIONS(state, listLikedAnimations) {
    state.listLikedAnimations = listLikedAnimations;

    if (!state.listLikedAnimations.length && state.intervalUpdateRenderId) {
      clearInterval(state.intervalUpdateRenderId);
      state.intervalUpdateRenderId = null;
    }
  },
  ADD_NEW_LIKED_ANIMATION: function ADD_NEW_LIKED_ANIMATION(state, likedAnimation) {
    if (state.listLikedAnimations.length < RTM_CONFIGS.MAXIMUM_RECEIVED_LIKES) {
      state.listLikedAnimations.push(likedAnimation);
    }

    if (!state.intervalUpdateRenderId) {
      state.intervalUpdateRenderId = setInterval(function () {
        if (state.listLikedAnimations[state.currentIndexRender + 1]) {
          state.currentIndexRender++;
          state.listRenderAnimations.push(state.listLikedAnimations[state.currentIndexRender]);
        }
      }, RTM_CONFIGS.DELAY_ANIMATION_LIKE);
    }
  },
  REMOVE_FIRST_LIKED_ANIMATION: function REMOVE_FIRST_LIKED_ANIMATION(state) {
    state.listLikedAnimations.splice(0, 1);
    state.listRenderAnimations.splice(0, 1);
    state.currentIndexRender--;

    if (!state.listLikedAnimations.length && state.intervalUpdateRenderId) {
      clearInterval(state.intervalUpdateRenderId);
      state.intervalUpdateRenderId = null;
    }
  },
  REQUEST_NEW_ANIMATION: function REQUEST_NEW_ANIMATION(state) {
    state.listRenderAnimations = state.listLikedAnimations.splice(0, 1);
  },
  UPDATE_TEXT_STICKER: function UPDATE_TEXT_STICKER(state, message) {
    state.channelTextSticker = message;
  }
};
var actions = {
  updateStreamDetail: function updateStreamDetail(_ref2, streamDetail) {
    var commit = _ref2.commit;
    commit("UPDATE_STREAM_DETAIL", streamDetail);
  },
  updateStreamStatistic: function updateStreamStatistic(_ref3, streamStatistic) {
    var commit = _ref3.commit;
    commit("UPDATE_STREAM_STATISTIC", streamStatistic);
  },
  updateHostInfo: function updateHostInfo(_ref4, hostInfo) {
    var commit = _ref4.commit;
    commit("UPDATE_HOST_INFO", hostInfo);
  },
  updateStreamProducts: function updateStreamProducts(_ref5, streamProducts) {
    var commit = _ref5.commit;
    commit("UPDATE_STREAM_PRODUCTS", streamProducts);
  },
  resortStreamProducts: function resortStreamProducts(_ref6, sortProduct) {
    var commit = _ref6.commit;
    commit("RESORT_STREAM_PRODUCTS", sortProduct);
  },
  updateListLikedAnimations: function updateListLikedAnimations(_ref7, listLikedAnimations) {
    var commit = _ref7.commit;
    commit("UPDATE_LIST_LIKED_ANIMATIONS", listLikedAnimations);
  },
  addNewLikedAnimation: function addNewLikedAnimation(_ref8, likedAnimation) {
    var commit = _ref8.commit;
    commit("ADD_NEW_LIKED_ANIMATION", likedAnimation);
  },
  removeFirstLikeAnimation: function removeFirstLikeAnimation(_ref9) {
    var commit = _ref9.commit;
    commit("REMOVE_FIRST_LIKED_ANIMATION");
  },
  updatechannelTextSticker: function updatechannelTextSticker(_ref10, message) {
    var commit = _ref10.commit;
    commit("UPDATE_TEXT_STICKER", message);
  },
  requestNewAnimation: function requestNewAnimation(_ref11) {
    var commit = _ref11.commit;
    commit("REQUEST_NEW_ANIMATION");
  }
};
export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};