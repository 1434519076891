//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { DEFAULT_ICON_STATES } from "@/configs/Icons";
import { STATUS_VIDEO } from "@/configs/SDK";
export default {
  name: "CardCarousel",
  props: {
    title: {
      type: String,
      default: "Hot Live"
    },
    dataSource: {
      type: Array
    },
    routerLink: {
      type: Object,
      default: function _default() {
        // return {
        // 	name: "",
        // 	params: {},
        // 	query: {}
        // }
        return null;
      }
    }
  },
  data: function data() {
    return {
      STATUS_VIDEO: STATUS_VIDEO,
      DEFAULT_ICON_STATES: DEFAULT_ICON_STATES,
      defaultImg: DEFAULT_ICON_STATES.defaultAvatar.activeSrc
    };
  },
  watch: {},
  computed: {},
  methods: {
    onErrorImage: function onErrorImage(e) {
      e.target.src = this.defaultImg;
    }
  }
};