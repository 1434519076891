import _objectSpread from "/home/ubuntu/projects/WebStream/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import Vue from "vue";
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from "vee-validate";
import { required, confirmed, alpha_num, max, min, email, numeric } from "vee-validate/dist/rules";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { i18n } from "@/plugins/i18n";
import moment from "moment";
var format = "DD/MM/YYYY";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
setInteractionMode("eager");
extend("email", email); //Rules

extend("numeric", _objectSpread(_objectSpread({}, numeric), {}, {
  message: "{_field_} is number"
}));
extend("required", _objectSpread(_objectSpread({}, required), {}, {
  message: "{_field_} is required"
}));
extend("isCheck", {
  validate: function validate(val) {
    return val ? true : false;
  },
  message: "{_field_} is required"
});
extend("confirmed", _objectSpread(_objectSpread({}, confirmed), {}, {
  message: "{_field_} does not match"
}));
extend("alpha_num_underscrore", {
  validate: function validate(value) {
    if (!value) return true;
    var patt = new RegExp(/^[A-Za-z0-9_]+$/);
    return patt.test(value);
  },
  message: "{_field_} should only contains letters, numbers and/or underscores"
});
extend("alpha_num", _objectSpread(_objectSpread({}, alpha_num), {}, {
  message: "{_field_} should only contains letters and/or numbers"
}));
extend("min", _objectSpread(_objectSpread({}, min), {}, {
  message: "{_field_} must have at least {length} characters"
}));
extend("max", _objectSpread(_objectSpread({}, max), {}, {
  message: "{_field_} must not be greater than {length} characters"
}));
extend("phone_number", {
  params: ["countryCode"],
  validate: function validate(value, _ref) {
    var countryCode = _ref.countryCode;
    var phoneNumber = parsePhoneNumberFromString(value, countryCode);
    return phoneNumber && phoneNumber.isValid();
  },
  message: "Phone number invalid"
});
extend("notEqual", {
  params: ["target"],
  validate: function validate(value, _ref2) {
    var target = _ref2.target;
    if (!value || !target) return true;
    return value === target ? false : true;
  },
  message: "{_field_} cannot be your {target}"
});
extend("ageOver", {
  params: ["year"],
  validate: function validate(value, _ref3) {
    var year = _ref3.year;
    if (!value) return true;
    var currentYear = new Date().getFullYear();
    var selectedYear = moment(value, format).toDate().getFullYear();
    return currentYear - selectedYear >= year;
  },
  message: function message(_, _ref4) {
    var year = _ref4.year;
    return i18n.t("validations.messages.ageOver", {
      year: year
    });
  }
});