import Vue from "vue";
import InfiniteLoading from 'vue-infinite-loading';
Vue.use(InfiniteLoading, {
  slots: {
    noResults: "",
    noMore: "",
    // hide slot
    error: {
      render: function render(h) {
        return h('div');
      }
    }
  }
});