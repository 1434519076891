import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.exec.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import BInputWithValidation from "@/components/BInputWithValidation";
export default {
  name: "Select",
  components: {
    BInputWithValidation: BInputWithValidation
  },
  props: {
    vid: {
      type: String
    },
    rules: {
      type: String
    },
    name: {
      type: String
    },
    placeholder: {
      type: String
    },
    dataSource: {
      type: Array
    },
    dataText: {
      type: String
    },
    dataValue: {
      type: String
    },
    value: {
      type: [String, Number, Object],
      default: null
    },
    hasTemplate: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      showMenu: false,
      selected: null,
      inputSearch: ""
    };
  },
  computed: {
    getDataSource: function getDataSource() {
      var _this$dataSource,
          _this = this;

      return ((_this$dataSource = this.dataSource) === null || _this$dataSource === void 0 ? void 0 : _this$dataSource.filter(function (x) {
        var _x$_this$dataText, _x$_this$dataText$tri, _this$inputSearch, _this$inputSearch$tri;

        return ((_x$_this$dataText = x[_this.dataText]) === null || _x$_this$dataText === void 0 ? void 0 : (_x$_this$dataText$tri = _x$_this$dataText.trim()) === null || _x$_this$dataText$tri === void 0 ? void 0 : _x$_this$dataText$tri.toLowerCase().indexOf((_this$inputSearch = _this.inputSearch) === null || _this$inputSearch === void 0 ? void 0 : (_this$inputSearch$tri = _this$inputSearch.trim()) === null || _this$inputSearch$tri === void 0 ? void 0 : _this$inputSearch$tri.toLowerCase())) > -1;
      })) || [];
    },
    getSelectedText: function getSelectedText() {
      if (!this.selected) return "";
      return this.selected[this.dataText] || "";
    }
  },
  watch: {
    selected: function selected(val) {
      this.$emit("input", val);
    },
    value: function value(val) {
      this.selected = val || null;
    }
  },
  mounted: function mounted() {
    this.selected = this.value || null;
  },
  methods: {
    handleOpenMenu: function handleOpenMenu() {
      var _this2 = this;

      this.showMenu = true;
      this.$nextTick(function () {
        _this2.$refs.search.focus();
      });
    },
    handleCloseMenu: function handleCloseMenu() {
      this.showMenu = false;
      this.inputSearch = "";
    },
    handleBlurInput: function handleBlurInput() {},
    handleSelected: function handleSelected(item) {
      this.selected = item;
      this.handleCloseMenu();
    }
  }
};