import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/pages/Home";
import store from "@/store";
import ErrorGeneral from "@/pages/ErrorGeneral";
import Category from "@/pages/Category";
import WatchStream from "@/pages/WatchStream";
import UserProfile from "@/pages/UserProfile";
import CashOut from "@/pages/CashOut";
import RedirectSocialLogin from "@/pages/RedirectSocialLogin";
Vue.use(VueRouter);
var cookies = Vue.prototype.$cookies;
export var routes = [{
  path: "/",
  name: "Home",
  component: Home
}, {
  path: '*',
  name: 'NotFound',
  component: ErrorGeneral
}, {
  path: '/cash-out',
  name: 'Cashout',
  component: CashOut
}, {
  path: '/category/:tagId',
  name: 'Category',
  component: Category
}, {
  path: "/watch/:slug",
  name: "WatchStream",
  component: WatchStream
}, {
  path: "/auth/socials/callback/line",
  name: "RedirectSocialLogin",
  component: RedirectSocialLogin
}, {
  path: "/user-profile/:userName/:type?",
  name: "UserProfile",
  component: UserProfile,
  meta: {
    resetAuth: true
  }
}];
var router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior: function scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    };
  }
});
router.beforeEach(authGuard);

function authGuard(to, from, next) {
  var isAuth = cookies.get("access_token") || store.getters.isLoggedIn;

  if (to.matched.some(function (record) {
    return record.meta.requiresAuth;
  }) && !isAuth) {
    next({
      name: "Home"
    });
  } else {
    next();
  }
}

export default router;