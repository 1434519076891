export default {
  generalActions: {
    "Cancel": "Cancel",
    "OK": "OK",
    "Back": "Back",
    "Log in": "Log in",
    "Log out": "Log out",
    "Yes": "Yes",
    "No": "No",
    "Exit": "Exit",
    "Enter": "Enter",
    "Send": "Send",
    "Next": "Next",
    "Copy Link": "Copy Link",
    "Submit": "Submit"
  },
  generalTexts: {
    "Loading": "Loading...",
    "Date": "Date",
    "Username": "Username",
    "Live Trivia": "Live Trivia",
    "views": "views",
    "comment": "comment",
    "comments": "comments",
    "likes": "likes",
    "No Stream at the moment": "No Stream at the moment",
    "Live Ended": "Live Ended",
    "Host": "Host",
    "Viewers": "Viewers",
    "Viewer": "Viewer",
    "There is a problem": "There is a problem",
    "Please try again later": "Please try again later.",
    "Your account is logged in on another device": "Your account is logged in on another device",
    "This account has been banned": "This account has been banned",
    "Oops__Seems like your network has problem__ Please try again": "Oops! Seems like your network has problem. Please try again",
    "This will allow you to communicate to other users": "This will allow you to communicate to other users",
    "You have been muted by Admin": "You have been muted by Admin",
    "You are kicked out by Host": "You are kicked out by Host",
    "User have been kicked out stream": "User have been kicked out stream",
    "Tap to unmute": "Tap to unmute",
    "Please send you message later": "Please send you message later",
    "Your report was submitted": "Your report was submitted!",
    "You have already reported": "You have already reported",
    "Success": "Success"
  },
  liveTexts: {
    "Share this livestream!": "Share this livestream!",
    "Say something": "Say something...",
    "Share": "Share",
    "Resend": "Resend",
    "Done": "Done",
    "Wait": "Wait",
    "Leave Stream": "Leave Stream",
    "Live": "Live",
    "has joined the livestream": "has joined the livestream",
    "joined the livestream": "joined the livestream",
    "liked the livestream": "liked the livestream",
    "and ___ other viewer": "and {0} other viewer",
    "and ___ other viewers": "and {0} other viewers",
    "Pin message": "Pin message",
    "Unpin message": "Unpin message",
    "Sorry, your comment needs to be appropriate and should not contain any URL Please try again": "Sorry, your comment needs to be appropriate and should not contain any URL. Please try again.",
    "Sorry, its too late to join the game but you can definitely watch the fun!": "Sorry, it’s too late to join the game but you can definitely watch the fun!"
  },
  triviaTexts: {
    "How to play?": "How to play?",
    "Get ready to play": "Get ready to play...",
    "Lets go!": "Let's go!",
    "Leaderboard": "Leaderboard",
    "How to": "How to",
    "Instructions": "Instructions",
    "Revive": "Revive",
    "Lives": "Lives",
    "Life": "Life",
    "Winner list in ____(time)": "Winner list in {0}",
    "Got it!": "Got it!",
    "Remaining": "Remaining",
    "Times up": "Time's up",
    "Option": "Option",
    "Correct": "Correct",
    "Wrong": "Wrong",
    "Thats right!": "That's right!",
    "Answer": "Answer",
    "Answers": "Answers",
    "View results": "View results",
    "Disqualified": "Disqualified",
    "Live Trivia has already started!": "Live Trivia has already started!",
    "Sorry, this is ongoing and you cant join midway": "Sorry, this is ongoing and you can't join midway...",
    "Youre out of the game!": "You're out of the game!",
    "Aw, Okay": "Aw, Okay",
    "Get Revives": "Get Revives",
    "Revive Left": "Revive left",
    "Revive(s) Left": "Revive(s) left",
    "Youre out of Revive": "You're out of Revive",
    "Congratulations!": "Congratulations!",
    "Top ___": "Top {0}",
    "Time remaining": "Time remaining",
    "Countdown": "Countdown",
    "Question": "Question",
    "Game rules: Score the most correct answers to win_ Once you get a question wrong, youll be disqualified_ However, if you have Revives, you can use it to stay in the game!": "Game rules: Score the most correct answers to win. Once you get a question wrong, you'll be disqualified. However, if you have Revives, you can use it to stay in the game!",
    "You have ___ Revive(s) left": "You have {0} Revive(s) left.",
    "You have ___ Revive left": "You have {0} Revive left.",
    "Use Revive": "Use Revive",
    "Use": "Use",
    "Forfeit game": "Forfeit game",
    "Oh no, you got that wrong!": "Oh no, you got that wrong!",
    "No, its okay": "No, it's okay.",
    "Yes, use!": "Yes, use!",
    "Question in ____(time)": "Question in {0}",
    "Answer in ____(time)": "Answer in {0}",
    "Start Trivia in ____(time)": "Start Trivia in {0}",
    "___ credits": "{0} credits",
    "Congratulations! You won! You get:": "Congratulations! You won! <br />You get:",
    "Congratulations! You won! You get: ____ credits": "Congratulations! You won! <br />You get: {0} credits",
    "Congratulations! Each winner gets:": "Congratulations! <br />Each winner gets:",
    "Congratulations! Each winner gets: ____ credits": "Congratulations! <br />Each winner gets: {0} credits",
    "Aww, no winner today_ Better luck next time!": "Aww, no winner today. <br />Better luck next time!",
    "No one gets": "No one gets:",
    "winner": "winner",
    "winners": "winners",
    "Do not join late or you can only watch the fun": "Do not join late or you can only watch the fun",
    "Tap on the options to submit your answer under 10 seconds!": "Tap on the options to submit your answer under 10 seconds!",
    "One wrong answer and youll be out_ However, a Revive can be used once per game to save you!": "One wrong answer and you'll be out... However, a Revive can be used once per game to save you!",
    "Survive till the end to win!": "Survive till the end to win!",
    "The total prize pool will be split equally by all winners who will receive credits, yay!": "The total prize pool will be split equally by all winners who will receive credits, yay!",
    "Each winner gets ___ credits!": "Each winner gets {0} credits!"
  },
  reportTexts: {
    "Reason for reporting": "Reason for reporting",
    "Sexual content": "Sexual content",
    "Violent or repulsive content": "Violent or repulsive content",
    "Hateful or abusive content": "Hateful or abusive content",
    "Harmful dangerous acts": "Harmful dangerous acts",
    "Child abuse": "Child abuse",
    "Spam or misleading content": "Spam or misleading content",
    "Infringes my rights": "Infringes my rights"
  },
  "validations": {
    "messages": {
      "minmax": "{fieldName} should be between {min}–{max} characters",
      "email": "Email invalid",
      "phone": "Phone number invalid",
      "required": "{fieldName} is required",
      "confirmed": "{fieldName} does not match",
      "alphaNum": "{fieldName} should only contain letters, numbers, and/or underscores ",
      "min": "{fieldName} must have at least {length} characters",
      "max": "{fieldName} should not contain more than {length} characters",
      "ageOver": "You have to be at least {year} years old"
    }
  }
};