export var LIVE_SERVER = {
  SDP_URL: process.env.VUE_APP_BELIVESDK_WEB_APP_SDP_URL,
  APP_NAME: process.env.VUE_APP_BELIVESDK_WEB_APP_NAME
};
export var STATUS_RESPONSES = {
  USER_BLOCKED_BY_HOST: 1309,
  USER_BANNED_BY_ADMIN: 1120,
  USERNAME_HAS_BEEN_TAKEN: 759,
  USER_NOT_FOUND: 652,
  SUCCESS: 1,
  PRIVATE_ROOM: 798,
  MODEL_INVALID: 750,
  USER_LOGGED_ANOTHER_DEVICE: 1341,
  NOT_BUY_TICKET: 1424,
  PHONE_REGISTERED: 969,
  INCORRECT_PASSWORD: 966,
  PHONE_NOT_EXIST: 1310,
  PHONE_NOT_FOUND: 0,
  VERIFY_CODE_INVALID: 965,
  BELIVEID_EXIST: 758,
  UNAUTHORIZED: 401,
  MISSING_OTP_TOKEN: 963,
  INVALID_REFERRAL_CODE: 0,
  ALREADY_REPORTED: 738
};
export var SIGN_IN_TYPE = {
  GOOGLE: 0,
  FACEBOOK: 1,
  TWITTER: 2,
  INSTAGRAM: 3,
  LINE: 4,
  PHONE: 5,
  APPLE: 6
};
export var DEVICE_TYPES = {
  ANDROID: 0,
  IOS: 1,
  WEB: 2
};
export var DEVICE_UDID = {
  ANDROID: "ANDROID",
  IOS: "IOS",
  WEB: "WEB"
};
export var MOBILE_OS = {
  ANDROID: "ANDROID",
  iOS: "iOS",
  WINDOW_PHONE: "Windows Phone",
  UN_KNOWN: "unknown",
  BL_ANDROID: "BL Android",
  BL_iOS: "BL iOS"
};
export var DEFAULT_VALUES = {
  LOCATION: "VN",
  NUMBER_SUB_BANNERS: 2
};
export var EVENT_STATUS = {
  UPCOMING: 3,
  LIVE: 1,
  RECORDED: 2,
  COMING_SOON: 4
};
export var BANNER_TYPES = {
  MAIN: 0,
  SUB: 1
};
export var STATUS_VIDEO = {
  LIVE: 1,
  RECORDED: 2
};
export var STATUS_REMOTE_STREAM = {
  PLAYING: "PLAYING",
  STREAMING: "STREAMING",
  PAUSED: "PAUSED",
  STOPPED: "STOPPED",
  NEW: "NEW",
  END: "END"
};