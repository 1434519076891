/* eslint-disable no-unused-vars */
import Vue from "vue";
Vue.directive("scroll-infinite", {
  bind: function bind(el, binding, vnode) {
    el.onScrollEvent = function (event) {
      if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
        if (typeof binding.value === "function") {
          binding.value(el);
        }
      }
    };

    el.addEventListener("scroll", el.onScrollEvent);
  },
  unbind: function unbind(el) {
    el.removeEventListener("scroll", el.onScrollEvent);
  }
});