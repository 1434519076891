//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import VDatePicker from 'v-calendar/lib/components/date-picker.umd';
import moment from "moment";
import BInputWithValidation from "./BInputWithValidation";
export default {
  name: "DatePicker",
  components: {
    VDatePicker: VDatePicker,
    BInputWithValidation: BInputWithValidation
  },
  props: {
    maxDate: {
      type: Date,
      default: null
    },
    formGroupClass: {
      type: String
    },
    valueType: {
      type: String
    },
    rules: {
      type: String
    },
    name: {
      type: String
    },
    vid: {
      type: String
    },
    format: {
      type: String,
      default: "DD/MM/YYYY"
    },
    value: {}
  },
  data: function data() {
    return {
      //date: null,
      dateString: ""
    };
  },
  mounted: function mounted() {
    this.setDateString(this.value);
  },
  watch: {
    date: function date(val) {
      this.setDateString(val);
    }
  },
  computed: {
    date: {
      get: function get() {
        if (this.value && this.valueType === "string") return moment(this.value, this.format).toDate();
        return this.value;
      },
      set: function set(val) {
        var newDateVal = null;

        if (val && this.valueType === "string") {
          newDateVal = moment(val).format(this.format);
        } else newDateVal = val;

        this.$emit("input", newDateVal);
      }
    }
  },
  methods: {
    setDateString: function setDateString(val) {
      if (val && val != "") {
        this.dateString = moment(val).format(this.format);
      } else this.dateString = null;
    },
    handleBlur: function handleBlur() {
      var isValidDate = moment(this.dateString, this.format).isValid();
      if (isValidDate) this.date = moment(this.dateString, this.format).toDate();else this.date = null;
    }
  }
};