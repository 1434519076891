import _objectSpread from "/home/ubuntu/projects/WebStream/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import HelperMixins from "@/mixins/HelperMixins";
import { mapGetters } from "vuex";
import { i18n } from "@/plugins/i18n";
export default {
  name: "ErrorGeneral",
  mixins: [HelperMixins],
  props: {
    errorMessage: {
      type: String,
      default: i18n.t('generalTexts.There is a problem')
    },
    subErrorMessage: {
      type: String,
      default: i18n.t('generalTexts.Please try again later')
    }
  },
  computed: _objectSpread({}, mapGetters(["OSUsing"])),
  methods: {
    returnBack: function returnBack() {
      this.closePageAndRedirectPage(this.OSUsing);
    }
  }
};