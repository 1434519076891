var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"watch-stream-page page-container"},[_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(_vm.bgImg),expression:"bgImg",arg:"background-image"}],staticClass:"bg-fullwidth"}),_c('b-overlay',{attrs:{"spinner-variant":"primary","show":_vm.showLoadingPanel,"opacity":"1"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('LoadingPanel')]},proxy:true}])},[(_vm.streamDetail)?_c('div',{staticClass:"container live-box-wrapper"},[_c('div',{staticClass:"title-wrapper"},[_c('span',{staticClass:"title-wrapper__title"},[_vm._v(_vm._s(_vm.streamDetail.Title))]),_c('span',{staticClass:"title-wrapper__seperate"},[_vm._v(" - ")]),_c('span',{staticClass:"title-wrapper__publisher"},[_vm._v(_vm._s(_vm.streamDetail.Publisher.DisplayName))])]),_c('div',{staticClass:"live-box"},[_c('div',{staticClass:"live-box__video video-box",class:_vm.streamDetail.Status === _vm.STATUS_VIDEO.RECORDED
              ? 'video-recorded'
              : ''},[(!_vm.isStreamEnded)?_c('div',{staticClass:"live-box__wrapper"},[(
                _vm.streamDetail.IsRecorded &&
                _vm.streamDetail.Status === _vm.STATUS_VIDEO.RECORDED &&
                _vm.urlSources.length
              )?_c('VideoJsPlayer',_vm._g({ref:"videoJsPlayer",class:("embed-responsive " + _vm.classVideoMode + " " + (_vm.isVideo16d9 ? 'video-box-16d9' : '') + " " + (_vm.isMobileLayout ? 'mobile-layout' : '')),attrs:{"video-class":"embed-responsive-item","url-sources":_vm.urlSources,"force-auto-play-with-sound":true,"options":_vm.streamDetail.Status === _vm.STATUS_VIDEO.LIVE
                  ? _vm.videoOptions
                  : _vm.videoRecordOptions,"override-events":['ended', 'timeUpdate'],"time-waiting-for-switch-url":_vm.TIME_CHECK_STATUS_STREAM},on:{"ended":function($event){return _vm.updateEndResultStream()},"cannot-play":function($event){return _vm.updateEndResultStream()},"loaded-video":_vm.loadedMetaData}},
                _vm.streamDetail.IsRecorded &&
                _vm.streamDetail.Status === _vm.STATUS_VIDEO.RECORDED
                  ? { timeUpdate: _vm.onTimeVideoUpdate }
                  : null
              )):(_vm.streamDetail.Status === _vm.STATUS_VIDEO.LIVE)?_c('div',{staticClass:"video-live-box",attrs:{"id":"live-box-stream"}},[_c('div',{staticClass:"badge-status-item badge badge-danger"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.DEFAULT_ICON_STATES.network.activeSrc),expression:"DEFAULT_ICON_STATES.network.activeSrc"}],staticClass:"icon-badge",attrs:{"alt":"Live"}}),_c('span',{staticClass:"name-badge"},[_vm._v("Live")])])]):_vm._e(),(_vm.isShowLoadingVideo)?_c('OverlayLoading'):_vm._e()],1):_c('EndScreenStream',{attrs:{"data-end-stream":_vm.endResultStream,"cover":_vm.streamDetail.CoverImage}})],1),_c('div',{staticClass:"live-box__chat chat-box"},[(_vm.userChat)?_c('ChatBox',{ref:"chatBox",class:_vm.isMobileLayout ? 'mobile-layout' : '',attrs:{"server":_vm.server,"channel":_vm.streamDetail.Slug,"license":_vm.license,"user":_vm.userChat,"host-info":_vm.streamDetail.Publisher,"stream-statistic":_vm.streamStatistic,"local-text-messages":_vm.localTextMessages}},[_c('template',{slot:"prepend-middle-wrapper-layout"},[_c('div',{ref:"animationContainer",staticClass:"animation-box"},[_c('transition-group',{attrs:{"name":"flying-heart","tag":"div"},on:{"after-enter":_vm.afterEnterLikeTransition}},_vm._l((_vm.listRenderAnimations),function(likeAnimation,index){return _c('Heart',{key:likeAnimation.Index,attrs:{"data-index":index,"bg-class-animation":likeAnimation.BgClassAnimation,"animation-class":likeAnimation.AnimationClass}})}),1)],1)])],2):_vm._e()],1)])]):_vm._e()]),_c('div',{staticClass:"list-hot-video-wrapper"},[_c('CardCarousel',{staticClass:"hot-items-wrapper",attrs:{"dataSource":_vm.hotLiveVideos,"title":"Recommended broadcast"}})],1),_c('ConfirmModal',{attrs:{"text":_vm.waitingModalContent,"open":_vm.isShowWaitingConfirmModal,"modalOptions":_vm.waitingModalOptions},on:{"hidden":function($event){_vm.isShowWaitingConfirmModal = false},"cancel-confirm-modal":_vm.cancelWaitingVideo,"ok-confirm-modal":_vm.stillWaitingVideo}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }