import { DEFAULT_ICON_STATES } from "@/configs/Icons";
export default {
  name: "Helper Mixins",
  data: function data() {
    return {
      DEFAULT_ICON_STATES: DEFAULT_ICON_STATES
    };
  },
  created: function created() {},
  methods: {
    updateStateIcon: function updateStateIcon(icon) {
      var _this = this;

      var state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (this.isUsingMobile) {
        this.DEFAULT_ICON_STATES["".concat(icon)].isHover = state;
        setTimeout(function () {
          _this.DEFAULT_ICON_STATES["".concat(icon)].isHover = !state;
        }, 300);
      }
    }
  }
};