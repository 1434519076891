import LogoPrimary from '@/assets/media/waylive-logo.svg';
import LogoSecondary from '@/assets/media/logo.svg';
import GoogleStore from '@/assets/media/icons/gg-store.svg';
import AppStoreIcon from '@/assets/media/icons/app-store-icon.svg';
import GooglePlay from '@/assets/media/icons/google-play.png';
import AppStore from '@/assets/media/icons/app-store.png';
import Close from '@/assets/media/icons/close.svg';
import Play from '@/assets/media/icons/play.svg';
import DefaultAvatar from '@/assets/media/general/default-avatar.svg';
import DesertIsland from '@/assets/media/general/desert-island.svg';
import ArrowDown from '@/assets/media/icons/arrow-down.svg';
import Facebook from '@/assets/media/icons/fb.svg';
import Instagram from '@/assets/media/icons/ig.svg';
import Google from '@/assets/media/icons/gmail.svg';
import Line from '@/assets/media/icons/line.svg';
import Twitter from '@/assets/media/icons/twitter.svg';
import AppleRedIcon from '@/assets/media/icons/apple.svg';
import Heart from "@/assets/media/icons/heart.svg";
import GradientEye from "@/assets/media/icons/gradient-eye.svg";
import Fire from '@/assets/media/icons/fire.svg';
import Network from '@/assets/media/icons/network.svg';
import EyeOff from '@/assets/media/icons/off.svg';
import EyeOn from '@/assets/media/icons/on.svg';
import Gem from '@/assets/media/icons/gem.png';
import StarArtboard from '@/assets/media/icons/star-artboard.png';
export var DEFAULT_ICON_STATES = {
  logo: {
    isHover: false,
    activeSrc: LogoPrimary,
    inactiveSrc: LogoPrimary
  },
  logoSecondary: {
    isHover: false,
    activeSrc: LogoSecondary,
    inactiveSrc: LogoSecondary
  },
  heart: {
    isHover: false,
    activeSrc: Heart,
    inactiveSrc: Heart
  },
  gradientEye: {
    isHover: false,
    activeSrc: GradientEye,
    inactiveSrc: GradientEye
  },
  googleStore: {
    isHover: false,
    activeSrc: GoogleStore,
    inactiveSrc: GoogleStore
  },
  appStoreIcon: {
    isHover: false,
    activeSrc: AppStoreIcon,
    inactiveSrc: AppStoreIcon
  },
  googlePlay: {
    isHover: false,
    activeSrc: GooglePlay,
    inactiveSrc: GooglePlay
  },
  appStore: {
    isHover: false,
    activeSrc: AppStore,
    inactiveSrc: AppStore
  },
  close: {
    isHover: false,
    activeSrc: Close,
    inactiveSrc: Close
  },
  defaultAvatar: {
    isHover: false,
    activeSrc: DefaultAvatar,
    inactiveSrc: DefaultAvatar
  },
  play: {
    isHover: false,
    activeSrc: Play,
    inactiveSrc: Play
  },
  desertIsland: {
    isHover: false,
    activeSrc: DesertIsland,
    inactiveSrc: DesertIsland
  },
  arrowDown: {
    isHover: false,
    activeSrc: ArrowDown,
    inactiveSrc: ArrowDown
  },
  facebook: {
    isHover: false,
    activeSrc: Facebook,
    inactiveSrc: Facebook
  },
  instagram: {
    isHover: false,
    activeSrc: Instagram,
    inactiveSrc: Instagram
  },
  google: {
    isHover: false,
    activeSrc: Google,
    inactiveSrc: Google
  },
  line: {
    isHover: false,
    activeSrc: Line,
    inactiveSrc: Line
  },
  twitter: {
    isHover: false,
    activeSrc: Twitter,
    inactiveSrc: Twitter
  },
  appleRedIcon: {
    isHover: false,
    activeSrc: AppleRedIcon,
    inactiveSrc: AppleRedIcon
  },
  fire: {
    isHover: false,
    activeSrc: Fire,
    inactiveSrc: Fire
  },
  network: {
    isHover: false,
    activeSrc: Network,
    inactiveSrc: Network
  },
  eyeOff: {
    isHover: false,
    activeSrc: EyeOff,
    inactiveSrc: EyeOff
  },
  eyeOn: {
    isHover: false,
    activeSrc: EyeOn,
    inactiveSrc: EyeOn
  },
  gem: {
    isHover: false,
    activeSrc: Gem,
    inactiveSrc: Gem
  },
  starArtboard: {
    isHover: false,
    activeSrc: StarArtboard,
    inactiveSrc: StarArtboard
  }
};