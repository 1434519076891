import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import CryptoJS from "crypto-js"; // default KEY and iv if not given

var KEY = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_BELIVESDK_CRYPTO_KEY); //"" is the same as the background password

var IV = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_BELIVESDK_CRYPTO_IV); //"" is the same as the background

/**
 * AES encryption: string key iv returns base64
 */

export function Encrypt(word, keyStr, ivStr) {
  var key = KEY;
  var iv = IV;

  if (keyStr) {
    key = CryptoJS.enc.Utf8.parse(keyStr);
    iv = CryptoJS.enc.Utf8.parse(ivStr);
  }

  var srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}
/**
 * AES decryption: string key iv returns base64
 *
 */

export function Decrypt(word, keyStr, ivStr) {
  var key = KEY;
  var iv = IV;

  if (keyStr) {
    key = CryptoJS.enc.Utf8.parse(keyStr);
    iv = CryptoJS.enc.Utf8.parse(ivStr);
  }

  var base64 = CryptoJS.enc.Base64.parse(word);
  var src = CryptoJS.enc.Base64.stringify(base64);
  var decrypt = CryptoJS.AES.decrypt(src, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}