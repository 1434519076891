import { MOBILE_OS } from "@/configs/SDK";
var state = {
  isMobileLayout: false,
  isBottom: false,
  categories: [],
  showHeader: true,
  OSUsing: MOBILE_OS.UN_KNOWN
};
var getters = {
  streams: function streams(state) {
    return state.streams;
  },
  categories: function categories(state) {
    return state.categories;
  },
  isMobileLayout: function isMobileLayout(state) {
    return state.isMobileLayout;
  },
  isBottom: function isBottom(state) {
    return state.isBottom;
  },
  showHeader: function showHeader(state) {
    return state.showHeader;
  },
  OSUsing: function OSUsing(state) {
    return state.OSUsing;
  }
};
var mutations = {
  UPDATE_CATEGORIES: function UPDATE_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  UPDATE_MOBILE_BROWSER_LAYOUT: function UPDATE_MOBILE_BROWSER_LAYOUT(state, isMobileLayout) {
    state.isMobileLayout = isMobileLayout;
  },
  UPDATE_IS_BOTTOM: function UPDATE_IS_BOTTOM(state, isBottom) {
    state.isBottom = isBottom;
  },
  UPDATE_STATE_SHOW_HEADER: function UPDATE_STATE_SHOW_HEADER(state, showHeader) {
    state.showHeader = showHeader;
  },
  UPDATE_OS_USING: function UPDATE_OS_USING(state, OSUsing) {
    state.OSUsing = OSUsing;
  }
};
var actions = {
  updateIsBottom: function updateIsBottom(_ref, isBottom) {
    var commit = _ref.commit;
    commit("UPDATE_IS_BOTTOM", isBottom);
  },
  updateCategories: function updateCategories(_ref2, categories) {
    var commit = _ref2.commit;
    commit("UPDATE_CATEGORIES", categories);
  },
  updateMobileBrowserLayout: function updateMobileBrowserLayout(_ref3, isMobileLayout) {
    var commit = _ref3.commit;
    commit("UPDATE_MOBILE_BROWSER_LAYOUT", isMobileLayout);
  },
  updateStateShowHeader: function updateStateShowHeader(_ref4, showHeader) {
    var commit = _ref4.commit;
    commit("UPDATE_STATE_SHOW_HEADER", showHeader);
  },
  updateOSUsing: function updateOSUsing(_ref5, OSUsing) {
    var commit = _ref5.commit;
    commit("UPDATE_OS_USING", OSUsing);
  }
};
export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};