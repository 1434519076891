<template>
  <BeModal
    :modal-options="modalOptions"
    :loading="loading"
    @hidden="resetForm"
    :title="updateModal.title"
    :sub-title="updateModal.subTitle"
  >
    <template v-slot:default>
      <ValidationObserver ref="updateForm" v-slot="{ invalid }">
        <form @submit.prevent="updateInfoRegisterSocial">
          <BInputWithValidation
            type="text"
            placeholder="Display Name"
            class="mb-4"
            rules="required|min:3|max:16"
            name="DisplayName"
            vid="displayName"
            v-model="item.displayName"
          />
          <BInputWithValidation
            type="text"
            placeholder="User Name"
            class="mb-4"
            rules="required|min:3|max:16"
            name="UserName"
            vid="userName"
            v-model="item.userName"
          />
          <div class="w-100 text-center">
            <button
              type="submit"
              :disabled="invalid"
              class="btn btn-sm btn-primary btn-rounded btn-animated text-uppercase w-75"
            >
              {{ $t("Register") }}
            </button>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </BeModal>
</template>

<script>
import BeModal from "@/components/BeModal";
import BInputWithValidation from "@/components/BInputWithValidation";
import SdkMixins from "@/mixins/SdkMixins";
import {
  DEVICE_TYPES,
  DEVICE_UDID,
  SIGN_IN_TYPE,
  STATUS_RESPONSES,
} from "@/configs/SDK";
import { DEFAULT_ICON_STATES } from "@/configs/Icons";
import { STATUS_MESSAGES } from "@/messages/System";
export default {
  name: "UpdateUserInfoRegisterModal",
  mixins: [SdkMixins],
  components: {
    BeModal,
    BInputWithValidation,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    userSocial: {
      type: Object,
      default: () => {
        return {};
      },
    },
    signInType: {
      type: Number,
      default: SIGN_IN_TYPE.GOOGLE,
    },
  },
  data: () => ({
    DEFAULT_ICON_STATES,
    selected: null,
    loading: false,
    item: {
      displayName: "",
      userName: "",
      deviceType: DEVICE_TYPES.WEB,
      deviceUdid: DEVICE_UDID.WEB,
    },
    updateModal: {
      title: "",
      subTitle: "",
    },
    modalOptions: {
      id: "update-modal",
      class: "update-signup-modal-custom",
      dialogClass: "update-signup-modal",
      headerClass: "update-signup-modal__header",
      contentClass: "update-signup-modal__content",
      bodyClass: "update-signup-modal__body",
    },
  }),
  computed: {},
  watch: {
    open(val) {
      if (val) this.$bvModal.show(this.modalOptions.id);
    },
  },
  mounted() {
this.item = this.getBasicInfoUser(this.userSocial, this.signInType);    
// this.item.displayName = this.userSocial?.displayName || "";
    // this.item.userName = this.userSocial?.userName || "";
    if (this.open) this.$bvModal.show(this.modalOptions.id);
  },
  methods: {
    getBasicInfoUser(userSocial, signInType) {
      switch (signInType) {
        case SIGN_IN_TYPE.GOOGLE: {
          const profile = userSocial.getBasicProfile();
          return  {
            displayName: profile.getName(),
            userName: profile.getGivenName(),
            familyName: profile.getFamilyName(),
            imageUrl: profile.getImageUrl(),
            email: profile.getEmail()
          }
        }

        // case SIGN_IN_TYPE.FACEBOOK:
        //   return `${user.userID}`;
        // case SIGN_IN_TYPE.INSTAGRAM:
        //   return `${user.user_id}`;
        // case SIGN_IN_TYPE.APPLE:
        //   return `${user.userId}`;
        // case SIGN_IN_TYPE.LINE:
        //   return `${user.sub}`;
        default:
          return userSocial;
      }
    },

    async updateInfoRegisterSocial() {
      const isValid = await this.$refs.updateForm.validate();
      if (!isValid) return;
      this.loading = true;
      try {
        const { Code, Message } = await this.registerSocial({
          SignInId: this.getUserIdBySocialNetWork(
            this.userSocial,
            this.signInType
          ),
          UserName: this.item.userName,
          DisplayName: this.item.displayName,
          SignInType: this.signInType,
          DeviceType: 2,
          DeviceUdid: this.item.deviceUdid,
        });
        if (Code === STATUS_RESPONSES.SUCCESS) {
          this.$emit("success");
          await this.loginWithSocialNetwork(this.userSocial, this.signInType);
          this.handleFinishUpdate();
        } else {
          this.handleFormError(Code, Message);
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
      this.loading = false;
    },
    handleFormError(code, message) {
      if (code === STATUS_RESPONSES.USERNAME_HAS_BEEN_TAKEN) {
        this.$refs.updateForm.setErrors({
          userName: [this.$i18n.t(message)],
        });
      }
      this.$toastr.e(message, STATUS_MESSAGES.ERROR);
    },
    handleFinishUpdate() {
      this.$root.$emit("login-social-success");
      this.resetForm();

      this.$emit("hide");
      this.$bvModal.hide("update-modal");
      this.$emit("hidden");
    },
    resetForm() {
      this.item = {
        userName: "",
        displayName: "",
      };
      requestAnimationFrame(() => {
        this.$refs.updateForm?.reset();
      });
      this.$emit("hide");
      this.$emit("hidden");
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  font-size: 1.3rem;
  color: $color-grey-light-3;
  padding-top: 0.2rem;
  margin-left: 0.5rem;
  margin-top: 1.5rem;
}
.sub-actions {
  margin-top: 1rem;
  margin-bottom: 3rem;
  .forgot-pwd {
    color: #9b9b9b;
    font-family: $font-primary;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    &:hover {
      text-decoration: underline !important;
    }
  }
}
.action-update {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
</style>

