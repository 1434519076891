//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { DEFAULT_ICON_STATES } from "@/configs/Icons";
export default {
  name: "BeModal",
  components: {},
  props: {
    modalOptions: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    noCloseButton: {
      type: Boolean,
      default: false
    },
    logo: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: "Sign Up (1/2)"
    },
    subTitle: {
      type: String,
      default: "Please fill out the following fields to sign up"
    }
  },
  computed: {
    modalConfigs: function modalConfigs() {
      return Object.assign({
        id: "",
        class: "",
        dialogClass: "",
        headerClass: "",
        contentClass: "",
        bodyClass: ""
      }, this.modalOptions);
    }
  },
  data: function data() {
    return {
      DEFAULT_ICON_STATES: DEFAULT_ICON_STATES
    };
  },
  beforeDestroy: function beforeDestroy() {
    this.$emit("hide");
    this.$emit("hidden");
  }
};