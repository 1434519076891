export var STREAM_MESSAGES_CONFIG = {
  AUTO_GENERATE_TEST_MESSAGES: true,
  MAXIMUM_MESSAGES: 100,
  TIME_PER_MESSAGE: 500,
  // millisecond
  MAX_LIKE_IN_SECOND: 5,
  TIME_COUNT_LIKE_INTERVAL: 1000 // milliseconds

};
export var ENV_CONFIGS = {
  IS_LOCAL: process.env.NODE_ENV === "development",
  IS_HARD_URL: true,
  IS_SHOW_DEBUG: true,
  IS_DETECT_LOCATION: false,
  IS_SHOW_HOMEPAGE: false
};
export var LIMIT_CHAT_CONFIGS = {
  WAITING_TIME: 5,
  // seconds
  CAP_TARGET_MESSAGES: 40,
  THRESHOLD_MESSAGES: 60,
  INTERVAL: 1,
  // seconds
  IS_APPLY_LIMIT: true,
  MAXIMUM_MESSAGES: 100 // messages

};
export var STREAM_CONFIGS = {
  TIME_GET_STATISTIC: 30,
  // seconds
  TIME_TRACKING_LIKE: 10,
  // seconds
  TIME_CHECK_STATUS_STREAM: 120,
  // seconds
  MAXIMUM_RETRIES: 3,
  TIME_CHECK_STATUS_VOD: 20 // seconds

};
export var VIDEO_PLAYER_CONFIGS = {
  TIME_WAITING_ERROR: 30 // seconds,

};
export var MAPPING_URL_SOURCES = {
  BROADCASTER: "Broadcaster_feed",
  DATA_FEED: "feeds"
};
export var VIDEO_ASPECT_RATIO = 16 / 9;
export var ALLOW_GUEST_CHAT = false;
export var ALLOW_RANDOM_COLOR = false;
export var ALLOW_GUEST_JOINED_MESSAGE = false;
export var ALLOW_GUEST_JOIN_TO_WATCH = true;
export var FORCE_LANDSCAPE_SCREEN = false;
export var CHECK_SCREEN_MODE_BASED_DIMENSION_VIDEO = true;
export var RANDOM_COLORS = ["#E31F52", "#034530", "#F5A629", "#FFA3A6", "#3838A8"];
export var SCREEN_TYPES = {
  PORTRAIT: 0,
  LANDSCAPE: 1
};
export var REGEXS = {
  // eslint-disable-next-line
  ANY_URL: /(((https?:(\/\/)?(www\.)?([a-zA-Z0-9\-]+?\.)?)([a-zA-Z0-9\-]+?)\.([a-zA-Z]+))(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/gi,
  // eslint-disable-next-line
  BUKALAPAK_URL: /(((https?:(\/\/)?(www\.)?([a-zA-Z0-9\-]+?\.)?)(bukalapak)\.com)(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/gi
};
export var PUBLIC_SDK_SCRIPT = {
  PAYPAL: "https://www.paypal.com/sdk/js",
  GOOGLE: "https://apis.google.com/js/api:client.js",
  FACEBOOK: "https://connect.facebook.net/en_US/sdk.js",
  APPLE: "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
};
export var SOCIAL_URL = {
  FACEBOOK: "https://www.facebook.com/knock2liveofficial",
  INSTAGRAM: "https://instagram.com/knock2live_official?igshid=qexpzv1itatx",
  TELEGRAM: "https://t.me/knock_admin",
  WHATSAPP: "https://wa.me/message/7UO2EHJDOM4GJ1"
};
export var DEFAULT_CHECK_STATISTIC_TIME = 130; // secs

export var GOOGLE_PLAY_LINK = "";
export var APP_STORE_LINK = "";
export var RESOLUTION_ARR = {
  // https://docs.agora.io/en/Interactive%20Broadcast/API%20Reference/web/interfaces/agorartc.stream.html#setvideoprofile
  "120p": "120p_1",
  "360p": "360p_4",
  "480p": "480p_4",
  "720p": "720p_3"
};
export var routeWithAuths = ["UserProfile"];