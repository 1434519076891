import _objectSpread from "/home/ubuntu/projects/WebStream/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { DEFAULT_ICON_STATES } from "@/configs/Icons";
import { mapGetters } from "vuex";
import HelperMixins from "@/mixins/HelperMixins";
import { i18n } from "@/plugins/i18n";
export default {
  name: "EndScreenStream",
  mixins: [HelperMixins],
  data: function data() {
    return {
      DEFAULT_LOGO: DEFAULT_ICON_STATES.logo
    };
  },
  props: {
    title: {
      type: String,
      default: i18n.t("generalTexts.Live Ended")
    },
    cover: {
      type: String,
      default: ""
    },
    dataEndStream: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    onExit: {
      type: Function,
      default: function _default() {}
    }
  },
  computed: _objectSpread({}, mapGetters(["OSUsing"])),
  watch: {},
  mounted: function mounted() {},
  methods: {}
};