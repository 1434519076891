import _objectSpread from "/home/ubuntu/projects/WebStream/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
import { SIGN_IN_TYPE } from "../configs/SDK";
import { PUBLIC_SDK_SCRIPT } from "../configs/Settings";
export default {
  name: "SignInWithFacebook",
  mounted: function mounted() {
    this.init(document, "script", "facebook-jssdk", this.initFb);
  },
  methods: {
    init: function init(d, s, id, callBack) {
      var js,
          fjs = d.getElementsByTagName(s)[0];

      if (d.getElementById(id)) {
        return;
      }

      js = d.createElement(s);
      js.id = id;
      js.src = PUBLIC_SDK_SCRIPT.FACEBOOK;
      js.addEventListener("load", callBack);
      fjs.parentNode.insertBefore(js, fjs);
    },
    initFb: function initFb() {
      window.fbAsyncInit = function () {
        // eslint-disable-next-line no-undef
        var fb = FB;
        fb.init({
          appId: process.env.VUE_APP_FACEBOOK_APP_ID,
          cookie: true,
          xfbml: true,
          version: process.env.VUE_APP_FACEBOOK_API_VERSION
        });
        fb.AppEvents.logPageView();
      };
    },
    logInWithFacebook: function logInWithFacebook() {
      var _this = this;

      window.FB.login(function (response) {
        if (response.status === "connected") {
          window.FB.api("/me?fields=name,email", function (resProfile) {
            if (process.env.VUE_APP_ENV !== "production") console.log("FACEBOOK RESPONSE", _objectSpread(_objectSpread({}, response.authResponse), resProfile));

            _this.$emit("onSuccess", _objectSpread(_objectSpread({}, response.authResponse), resProfile), SIGN_IN_TYPE.FACEBOOK);
          });
        }
      });
    }
  }
};