import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "Radio",
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Radio"
    },
    options: {
      type: Array
    },
    value: {
      type: [String, Number, Boolean],
      default: null
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  methods: {}
};