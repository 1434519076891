import Vue from "vue";
import { BIconEyeFill, BIconX, BIconJustify, BIconChevronDown, BModal, ModalPlugin, DropdownPlugin, BButton, BOverlay, BImg, BCarousel, CarouselPlugin, BAvatar, BEmbed, BTabs, BTab, BRow, BCol, BProgress, BProgressBar, BTooltip, BFormInput, BFormRadio, BNavbar, BNavbarBrand, BNavbarNav, BNavbarToggle, BCollapse, BNavItem, BNavForm, BNavItemDropdown, BIconThreeDots } from "bootstrap-vue";
Vue.component("b-modal", BModal);
Vue.use(ModalPlugin);
Vue.use(DropdownPlugin);
Vue.component("b-button", BButton);
Vue.component("b-overlay", BOverlay);
Vue.component("b-img", BImg);
Vue.component("b-carousel", BCarousel);
Vue.use(CarouselPlugin);
Vue.component("b-avatar", BAvatar);
Vue.component("b-icon-justify", BIconJustify);
Vue.component("b-icon-chevron-down", BIconChevronDown);
Vue.component("b-icon-eye-fill", BIconEyeFill);
Vue.component("b-icon-x", BIconX);
Vue.component("b-embed", BEmbed);
Vue.component("b-tabs", BTabs);
Vue.component("b-tab", BTab);
Vue.component("b-row", BRow);
Vue.component("b-col", BCol);
Vue.component("b-progress", BProgress);
Vue.component("b-progress-bar", BProgressBar);
Vue.component("b-tooltip", BTooltip);
Vue.component("b-form-input", BFormInput);
Vue.component("b-form-radio", BFormRadio);
Vue.component("b-navbar", BNavbar);
Vue.component("b-navbar-brand", BNavbarBrand);
Vue.component("b-navbar-nav", BNavbarNav);
Vue.component("b-navbar-toggle", BNavbarToggle);
Vue.component("b-collapse", BCollapse);
Vue.component("b-nav-item", BNavItem);
Vue.component("b-nav-form", BNavForm);
Vue.component("b-nav-item-dropdown", BNavItemDropdown);
Vue.component("b-icon-three-dots", BIconThreeDots);