//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "Dropdown",
  props: {
    title: {
      type: String
    },
    right: {
      type: Boolean
    },
    value: {
      type: String,
      default: ""
    },
    active: {
      type: Boolean,
      default: false
    },
    contentStyle: {
      type: [String, Object]
    },
    showDropdownIcon: {
      type: [Boolean, String],
      default: ""
    }
  },
  data: function data() {
    return {
      show: false,
      selected: null
    };
  },
  methods: {
    hideContent: function hideContent() {
      this.show = false;
    }
  }
};