import { i18n } from "@/plugins/i18n";
export var RTM_MESSAGES = {
  MESSAGE: 1,
  USER_LEFT: 101,
  USER_JOIN: 100,
  START_SHARE_SCREEN: "START_SHARE_SCREEN",
  STOP_SHARE_SCREEN: "STOP_SHARE_SCREEN",
  HOST_STREAM_ENDED: i18n.t('generalTexts.Stream Ended'),
  HOST_STARTED_STREAM: "HOST STARTED STREAM",
  HOST_UN_LOCKED_ROOM: "HOST UN-LOCKED ROOM",
  HOST_LOCKED_ROOM: "HOST LOCKED ROOM",
  LIKE_STREAM: "LIKE STREAM"
};
export var LIST_REASONS = [i18n.t('reportTexts.Sexual content'), i18n.t('reportTexts.Violent or repulsive content'), i18n.t('reportTexts.Hateful or abusive content'), i18n.t('reportTexts.Harmful dangerous acts'), i18n.t('reportTexts.Child abuse'), i18n.t('reportTexts.Spam or misleading content'), i18n.t('reportTexts.Infringes my rights')];
export var DEFAULT_TRIVIA_MESSAGES = {
  YOU_WIN_TRIVIA_TITLE: i18n.t('triviaTexts.Congratulations! You won! You get:'),
  NO_WINNER_TRIVIA_TITLE: i18n.t('triviaTexts.Aww, no winner today_ Better luck next time!'),
  DEFAULT_LEADER_BOARD_TRIVIA_TITLE: i18n.t('triviaTexts.Congratulations! Each winner gets:')
};
export var MEDIA_MESSAGES = {
  NO_MEDIAS: "Please connect camera and micro!",
  NO_CAMERA: "Please connect camera!",
  NO_MIC: "Please connect micro!"
};
export var STATUS_MESSAGES = {
  ERROR: "Error",
  SUCCESS: i18n.t('generalTexts.Success'),
  WARNING: "Warning",
  INFO: "Info"
};
export var SYSTEM_MESSAGES = {
  LIVE_ENDED: "Live stream ended!",
  DATA_NOT_FOUND: "Not Found Data!",
  USER_WAS_BLOCKED: "User was blocked!",
  STREAM_NOT_EXIST: "This stream not exist!",
  CONTACT_ADMIN: "Please contact admin for more detail!",
  FORCE_LOGIN: "You have to login to watch!",
  NUMBER_REGISTERED: "The number has been registered!",
  USER_UPDATE_PROFILE_SUCCESS: "User updated profile successfully!",
  DELETE_PHOTO_PROFILE_SUCCESS: "Delete photo profile successfully!",
  LOGGED_IN_SUCCESS: 'Logged success!',
  DELETE_STREAM_SUCCESS: "Delete stream successfully!",
  RECORDED_STREAM_SUCCESS: "Recorded stream successfully!",
  REPORT_STREAM_SUCCESS: i18n.t('generalTexts.Your report was submitted'),
  YOU_ALREADY_REPORTED: i18n.t('generalTexts.You have already reported'),
  INVALID_DATE: "N/A",
  MAX_LENGTH_CHAT: "You have exceeded the chat characters limit",
  INTERRUPTED_INTERNET: "Your network connection seems to be unstable. Please use WiFi or move to an area with better network quality!",
  BANNED_WORD: "使用できない単語が含まれています。",
  USER_LOGGED_IN_ANOTHER_ACCOUNT: i18n.t('generalTexts.Your account is logged in on another device')
};
export var ROOM_CHANNEL_MESSAGES = {
  LOCKED_ROOM: "The room was locked successfully!",
  UN_LOCKED_ROOM: "The room was un-locked successfully!",
  USER_WAS_KICKED_ROOM: i18n.t('generalTexts.You are kicked out by Host'),
  USER_WAS_KICKED_OUT_STREAM: i18n.t('generalTexts.User have been kicked out stream'),
  HOST_KICK_USER_SUCCESS: "Kick viewer successfully!",
  WARNING_BEFORE_LEAVE_LIVE_PAGE: "Do you really want to leave? You haven't stop stream!",
  DELETE_STREAM_CONFIRM: "Are you sure you want to delete this stream?",
  NOT_CONNECTED_CHAT_SERVER: "You didn't connect chat server, please refresh to connect or contact admin",
  RECONNECTING_CHAT: "Reconnecting chat server ....",
  RECONNECT_CHAT_SUCCESS: "Reconnected chat server!",
  SPAM_CHAT_MESSAGE: "You are typing too fast, please send your message later.",
  TRY_SEND_MESSAGE_AGAIN: i18n.t('generalTexts.Please send you message later'),
  NAUGHTY_WARNING: i18n.t('liveTexts.Sorry, your comment needs to be appropriate and should not contain any URL Please try again'),
  MUTED_BY_ADMIN: i18n.t('generalTexts.You have been muted by Admin'),
  BANNED_BY_ADMIN: i18n.t('generalTexts.This account has been banned')
};