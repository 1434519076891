var getters = {
  isShowInThisRelease: function isShowInThisRelease(state) {
    return state.isShowInThisRelease;
  },
  layoutModes: function layoutModes(state) {
    return state.layoutModes;
  },
  forgotPasswordSteps: function forgotPasswordSteps(state) {
    return state.forgotPasswordSteps;
  },
  loginSteps: function loginSteps(state) {
    return state.loginSteps;
  },
  signUpSteps: function signUpSteps(state) {
    return state.signUpSteps;
  }
};
export default getters;