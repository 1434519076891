export var AGORA_PLAYER_STATUS = {
  PLAY: "play",
  ABORTED: "aborted",
  PAUSED: "paused"
};
export var AGORA_PLAYER_REASONS = {
  PLAYING: "playing",
  STALLED: "stalled",
  PAUSE: "pause",
  SUSPEND: "suspend",
  CAN_PLAY: "canplay",
  TIMER: "timer"
};