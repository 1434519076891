//
//
//
//
//
//
import HelperMixins from "@/mixins/HelperMixins";
import { SIGN_IN_TYPE } from "../configs/SDK";
import { PUBLIC_SDK_SCRIPT } from "../configs/Settings";
export default {
  name: "SignInWithGoogle",
  mixins: [HelperMixins],
  data: function data() {
    return {
      auth2: null
    };
  },
  mounted: function mounted() {
    this.initScript(document, "script", {
      src: PUBLIC_SDK_SCRIPT.GOOGLE,
      id: "gg-js"
    }, this.setLoaded);
  },
  methods: {
    setLoaded: function setLoaded() {
      var _this = this;

      window.gapi.load("auth2", function () {
        // Retrieve the singleton for the GoogleAuth library and set up the client.
        _this.auth2 = window.gapi.auth2.init({
          client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          cookiepolicy: "single_host_origin" // Request scopes in addition to 'profile' and 'email'
          //scope: 'additional_scope'

        });

        _this.attachSignin(document.getElementById("google-sign-in-button"));
      });
    },
    attachSignin: function attachSignin(element) {
      var _this = this;

      this.auth2.attachClickHandler(element, {}, function (googleUser) {
        if (process.env.VUE_APP_ENV !== "production") {
          var profile = googleUser.getBasicProfile();
          console.log("GOOGLE RESPONSE", {
            id_token: googleUser.getAuthResponse().id_token,
            id: profile.getId(),
            full_name: profile.getName(),
            given_name: profile.getGivenName(),
            family_name: profile.getFamilyName(),
            image_url: profile.getImageUrl(),
            email: profile.getEmail()
          });
        }

        _this.$emit("onSuccess", googleUser, SIGN_IN_TYPE.GOOGLE);
      }, function (error) {
        console.log(error);
        console.error(JSON.stringify(error, undefined, 2));
      });
    }
  }
};